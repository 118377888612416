import self from "../img/self.png"
import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"
import SDPN from "../img/san-diego-pride-network.gif"
import CrookedCredenza from "../img/crooked-credenza.gif"
import ChickleChat from "../img/chickle-chat.gif"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Shaun",
    lastName: "Stephenson",
    initials: "sfs", // the example uses first and last, but feel free to use three or more if you like.
    position: "a Full Stack Web Developer",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕️',
            text: 'fueled by espresso'
        },
        {
            emoji: '☀️',
            text: 'based in sunny socal'
        },
        {
            emoji: "🗺️",
            text: "intern at sd startup map"
        },
        {
            emoji: "📧",
            text: "shaun@shaunfitzgarald.com"
        }
    ],
    socials: [
        {
            link: "https://github.com/shaunfitzgarald",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://linkedin.com/in/shaunfitzgarald",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://docs.google.com/document/d/e/2PACX-1vSu3iLV4A-zvOIZtq3ymnkx3uq551UR3oVWr1bZtpUKA3uRMXP-czndZ4l53vZG715b4Dk9umo-k6sx/pub",
            icon: 'fa fa-file-text-o',
            label: 'resume'
        },
        {
            link: "https://instagram.com/shaunfitzgarald",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://twitter.com/shaunfitzgarald",
            icon: "fa fa-twitter",
            label: 'twitter'
        },
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Hey there! I'm Shaun. Having recently completed an internship at SD Startup Map and graduated from LEARN Academy, I'm eagerly searching for new opportunities where I can apply my skills and enthusiasm. I'm passionate about German hip-hop and the proud owner of a three-legged dog named Bono. I'm known for my ability to channel my love for coffee into efficient and effective coding, and for transforming pizza breaks into innovative problem-solving sessions. If you're looking for a dedicated and creative team member to add a fresh perspective to your projects, I'd love to connect!",
    skills:
        {
            proficientWith: ['ruby', 'ruby on rails', 'javascript', 'react', 'firebase', 'git', 'github', 'bootstrap', 'react-strap', 'html5', 'aos', 'haml', 'css', 'npm', 'webpack', 'postgresql', 'tailwindcss'],
            exposedTo: ['nodejs', 'swift', 'css3', 'react native', 'figma', 'wordpress', 'webpack', 'nodejs', 'nextjs', 'mongodb', 'aws']
        }
    ,
    hobbies: [
        {
            label: 'music',
            emoji: '🎧'
        },
        {
            label: 'dancing',
            emoji: '💃'
        },
        {
            label: 'movies',
            emoji: '🎥'
        },
        {
            label: 'cooking',
            emoji: '🌶'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "ChickleChat: a social network",
            live: "https://chickle-chat.onrender.com",
            source: "https://github.com/shaunfitzgarald/chickle-chat.git",
            image: ChickleChat
        },
        {
            title: "The Crooked Credenza: a site for an interior designer",
            live: "https://the-croked-credenza.onrender.com",
            source: "https://github.com/shaunfitzgarald/the-croked-credenza.git",
            image: CrookedCredenza
        },
        {
            title: "San Diego Pride Network: an events platform",
            live: "https://sand-diego-pride-network.onrender.com",
            source: "https://github.com/shaunfitzgarald/san-diego-pride-network.git",
            image: SDPN
        },
        {
            title: "San Diego Startup Map: an app to make startups more visible",
            live: "https://sandiegostartupmap.com",
            image: mock4
        },
        {
            title: "BrickByBrick: a site for lego enthusiasts",
            live: "https://brick-by-brick-frontend.onrender.com",
            source: "https://github.com/byteSizeFox/brick-by-brick-frontend",
            image: mock3
        },
        {
            title: "Pig Latin Translator: a web app to translate english into pig latin",
            live: "https://shaunfitzgarald.github.io/pig-latin-translator/",
            source: "https://github.com/shaunfitzgarald/pig-latin-translator.git",
            image: mock2
        },
        {
            title: "Tic Tac Toe: a web app to play tic tac toe",
            live: "https://shaunfitzgarald.github.io/tic-tac-toe-app/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "https://github.com/shaunfitzgarald/tic-tac-toe-app.git", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1
        }
    ]
}